@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
* {
  font-family: "Inter", sans-serif;
}
.wrap-cl {
  text-wrap: nowrap;
}
.texture {
  background-image: url(./assets/bg.svg);
}
/* .texture1 {
	background-image: url(./assets/bg2.svg);
} */
.white-shadow {
  text-shadow: 2px 2px 4px #ffffff;
}
.about {
  background-image: url(./assets/tmull_bg.png);
  background-size: 200px;
}
.preview {
  background-image: url(./assets/preview.png);
  background-position: center;
  background-size: cover;
}
.todo1 {
  background-image: url(./assets/zurg1.png);
  background-position: center right;
}
.todo {
  background-image: url(./assets/zurg1.png);
  background-position: center left;
}
/* .shadow-lg-bt {
  --tw-drop-shadow: drop-shadow(0 1px 8px #ffffffbe)
    drop-shadow(0 4px 3px #ffffff);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
} */
.repeater--sized {
  height: 18px;
}
.image {
  animation: change-img-anim 15s infinite;
}
.bg-effect {
  animation: change-figure 15s;
}
.continue {
  animation: appear 15s;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes change-figure {
  0% {
    width: 0%;
    height: 0%;
  }
  10% {
    width: 190%;
    height: 190%;
  }
  50% {
    width: 190%;
    height: 100%;
  }
  90% {
    width: 40%;
    height: 190%;
  }
  100% {
    width: 0%;
    height: 0%;
  }
}
@keyframes change-img-anim {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
label {
  position: absolute;
  left: 1ch;
  top: 1.2em;
  transition: 0.5s;
  font-size: 1.05em;
}
.input {
  padding: 5px;
  margin-top: 1em;
}

/* metto spazio perchè label è all'interno di div*/
.div:hover label,
.input:focus + label,
.input:not(:placeholder-shown) + label {
  top: 0.1em;
  left: 2ch;
  font-size: 0.8em;
  transition: 0.2s;
}
.marquee {
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}
.blur-ultra {
  --tw-blur: blur(100px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.flip-card {
  perspective: 2000px;
}

.flip-card-inner {
  transition: transform 0.8s;
  transition-delay: 0.2s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dark .dark\:card-front,
.dark .dark\:card-back {
  filter: drop-shadow(0 0px 13px rgba(255, 255, 255, 0.2))
    drop-shadow(0 8px 5px rgba(255, 255, 255, 0.1));
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-back {
  transform: rotateY(180deg);
}
@font-face {
  font-family: myFirstFont;
  src: url(./assets/cmdashitseden.ttf);
}

.mnglbchg {
  font-family: myFirstFont;
}
.flip-card-inner1 {
  transform-style: preserve-3d;
  transition: transform 0.8s;
  transition-delay: 0.2s;
}
.flip-card1.flipped .flip-card-inner1 {
  transform: rotateY(180deg);
}
.flip-card-front1,
.flip-card-back1 {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.flip-card-back1 {
  transform: rotateY(180deg);
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
.snowflake {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
.s-none {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
