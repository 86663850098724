.editor-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.editor-wrapper,
.preview-wrapper {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
}

.preview-wrapper {
  background-color: #f9f9f9;
}

.preview-content {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Additional styles for preview content */
.preview-content h1 {
  font-size: 2em;
  margin: 0.5em 0;
}

.preview-content h2 {
  font-size: 1.5em;
  margin: 0.5em 0;
}

.preview-content blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  margin: 1em 0;
  color: #555;
  font-style: italic;
}

.preview-content ul,
.preview-content ol {
  padding-left: 1.5em;
  margin: 1em 0;
}

.preview-content li {
  margin: 0.5em 0;
}

.preview-content pre {
  background-color: #f4f4f4;
  padding: 10px;
  overflow: auto;
}
ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
